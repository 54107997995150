<template>
  <div class="behold-content-wrapper">
    <section
      class="pt-8 lg:pt-16 pb-20 container-contents lg:pb-120 font-AvenirNext"
    >
      <div>
        <Logo class="flex justify-start" />
      </div>

      <div class="mt-20 lg:mt-24">
        <div class="flex justify-center">
          <figure class="w-1/2 lg:w-1/3">
            <img
              src="../assets/images/timestudy/timestudy-top.png"
              alt="Download mobile app"
            />
          </figure>
        </div>

        <div class="flex w-full justify-center mt-20 lg:mt-120">
          <figure class="w-full mx-auto">
            <img
              src="../assets/images/timestudy/timestudy-banner.png"
              alt="Screenshot of Timestudy App"
            />
          </figure>
        </div>

        <div class="w-full lg:w-1/2">
          <p class="banner-section-text xl:pr-4">
            Time Study Now (TS Now) is a mobile app which allows individuals to
            to seamlessly track and analyse how they spend their time, so that
            they can optimise their time and life. The first version of the
            application was made to serve Time Study’s existing enterprise
            health-care clients.
          </p>
        </div>
      </div>
    </section>

    <article class="py-20 lg:py-120 text-black-dark bg-white usability-testing">
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Context</h5>
          <p class="article-paragraph font-normal">
            Time Study (TS) is an enterprise software solutions provider for
            managing time and effort data. TS primarily works in health-care,
            and serves 30+ hospitals. Hospitals and health-care systems are
            required to conduct periodic (usually quarterly) time studies that
            document how health-care practitioners spend their time. These time
            studies are used by the government to compensate hospitals, and by
            administrators to monitor and adapt processes.
          </p>
          <p class="article-paragraph mt-2 font-normal">
            One of the biggest pain points for TS’ clients was the manual
            process for gathering time use data. Before TS Now, human observers
            would follow practitioners around for a week and record all their
            activities. Observers would then map their notes to activity codes
            on Time Study’s web platform.
          </p>
          <p class="article-paragraph mt-2 font-normal">
            TS partnered with Enle Inc. to design and build Time Study Now which
            would let practitioners seamlessly track their time, and give
            visibility to admins while eliminating busy work and human errors.
          </p>
        </div>
      </div>
    </article>

    <section
      class="pt-20 lg:pt-120 bg-purple-lighter what-unique unique-timestudy"
    >
      <div class="container-contents">
        <h3 class="question-section-title text-purple-light">
          What’s unique about TS Now?
        </h3>
        <!--- First Image -->
        <article>
          <div
            class="container mx-auto flex lg:flex-row flex-col items-center mt-20 lg:mt-120"
          >
            <div class="lg:w-1/2 text-left">
              <h5 class="article-title">Log Activities</h5>

              <p class="article-paragraph xl:pr-2">
                Practitioners can select from a list of activities and track how
                much time they’re spending on it. Activity lists are based on
                the health-care organisations they practice with.
              </p>
            </div>

            <div class="w-full lg:w-3/6 lg:ml-7 mt-16 lg:mt-0">
              <img
                class="object-cover object-center rounded"
                src="../assets/images/timestudy/timestudy-3.png"
                alt="Screenshot of Timestudy App"
              />
            </div>
          </div>
        </article>

        <!--- Second Image -->
        <article class="mt-20 lg:mt-120">
          <div class="container mx-auto flex lg:flex-row flex-col items-center">
            <div class="w-full lg:w-3/6 order-2 lg:order-1 mt-16 lg:mt-0">
              <img
                class="object-cover object-center rounded"
                src="../assets/images/timestudy/timestudy-4.png"
                alt="Screenshot of Timestudy App"
              />
            </div>
            <div class="lg:w-1/2 text-left order-1 lg:order-2 lg:ml-7">
              <h5 class="article-title">
                View, Edit & Share Summaries of Activity Sessions
              </h5>
              <p class="article-paragraph">
                Practitioners can view, edit, and share summaries of activities.
                Summaries show a breakdown of practitioners time by activity and
                also the total time spent on all activities within a certain
                time period. Summaries can be filtered by day, week and custom
                time period.
              </p>
            </div>
          </div>
        </article>

        <!--- Third Image -->
        <article class="mt-20 lg:mt-120">
          <div class="container mx-auto flex lg:flex-row flex-col items-center">
            <div class="lg:w-1/2 text-left">
              <h5 class="article-title">Record Voice Notes</h5>

              <p class="article-paragraph xl:pr-2">
                Practitioners can record voice notes as they carry out
                activities.
              </p>
            </div>

            <div class="w-full lg:w-3/6 lg:ml-7 mt-16 lg:mt-0">
              <img
                class="object-cover object-center"
                src="../assets/images/timestudy/timestudy-5.png"
                alt="Screenshot of Timestudy App"
              />
            </div>
          </div>
        </article>
      </div>
    </section>

    <vm-back-top :bottom="100" :duration="1000" :timing="'ease'">
      <div class="back-to-top-arrow">
        <svg
          class="h-12 w-12 lg:w-79 lg:h-79"
          viewBox="0 0 79 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.4999 75.9411C59.6253 75.9411 75.9402 59.6263 75.9402 39.5008C75.9402 19.3754 59.6253 3.06055 39.4999 3.06055C19.3744 3.06055 3.05957 19.3754 3.05957 39.5008C3.05957 59.6263 19.3744 75.9411 39.4999 75.9411Z"
            fill="#440A67"
            fill-opacity="0.24"
          />
          <path
            d="M56.9711 48.0065C55.7778 49.1936 53.8503 49.1936 52.657 48.0065L39.5311 34.8806L26.4052 48.0065C25.1232 49.1049 23.1895 48.9549 22.0911 47.673C21.109 46.5288 21.109 44.8368 22.0911 43.6925L37.3893 28.3943C38.5826 27.2071 40.5101 27.2071 41.7034 28.3943L57.0016 43.6925C58.1797 44.8949 58.1674 46.8224 56.9711 48.0065Z"
            fill="white"
          />
        </svg>
      </div>
    </vm-back-top>

    <section class="py-20 lg:py-120 how-we-got-here bg-white">
      <article class="container-contents">
        <h3 class="question-section-title text-gray-lighter">
          So how did we get here?
        </h3>

        <div class="bg-white mt-20 lg:mt-120">
          <div class="container mx-auto lg:w-2/3">
            <h5 class="article-title">Team & Role</h5>
            <p class="article-paragraph text-black-dark font-normal">
              Our team was made up of 2 Product Owners, 1 Product Manager, 2
              Software Developers and a UX Designer (myself). I handled the UX
              and UI design for this project.
            </p>
          </div>
        </div>

        <div class="bg-white mt-20 lg:mt-120">
          <div class="container mx-auto lg:w-2/3">
            <h5 class="article-title">Time Study Now Goals</h5>
            <p class="article-paragraph mt-6 lg:mt-8">
              The goals and challenges which needed to be addressed which were:
            </p>

            <ul class="list-disc ml-4 mt-4 lg:mt-6">
              <li class="my-4 article-paragraph">
                Allow practitioners to track their time use with a mobile app
              </li>
              <li class="my-4 article-paragraph">
                Allow practitioners to view, edit and share time use statistics
              </li>
              <li class="my-4 article-paragraph">
                Allows practitioners to link their accounts to multiple
                health-care organisations
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>

    <section
      class="py-20 lg:pt-120 lg:pb-20 bg-purple-lighter text-purple what-unique timestudy-behold"
    >
      <div
        class="container-contents flex flex-wrap justify-between items-center"
      >
        <article class="w-full lg:w-1/2">
          <div class="xl:w-11/12">
            <h5 class="article-title">Wireframing & Prototyping</h5>
            <p class="article-paragraph font-medium">
              Based on the product requirements, I created wireframes and
              prototypes. We experimented with different ways practitioners
              could carry out time studies. We had to keep in mind that
              practitioners constantly change locations within hospitals while
              carrying out activities. Starting and stopping time studies needed
              to be as frictionless as possible and not interfere with the
              activities being carried out.
            </p>

            <p class="article-paragraph font-medium">
              We also explored what to do if practitioners forget to stop timing
              an activity before moving on to the next and, how to easily and
              safely allow practitioners to link their accounts with multiple
              health-care organisations.
            </p>
          </div>
        </article>

        <div class="w-full flex justify-end lg:w-3/6 mt-16 lg:mt-0">
          <figure>
            <img
              src="../assets/images/timestudy/timestudy-6.png"
              alt="screenshot of timestudy app"
            />
          </figure>
        </div>
      </div>
    </section>

    <article class="py-20 lg:py-120 text-black-dark bg-white usability-testing">
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Usability Testing</h5>
          <p class="article-paragraph">
            Due to Time Study’s specific target audience (health-care
            practitioners), not just anyone could be recruited for the usability
            tests. We needed a deep understanding of the context and environment
            practitioners would be carrying out time studies in. This would help
            to ensure that Time Study Now helps practitioners and organisations
            achieve their goals. Time Study recruited some healthcare
            practitioners and organisations they had built strong relationships
            with for the tests. Their feedback and insights generated were used
            to update the design.
          </p>
        </div>
      </div>
    </article>

    <section
      class="py-20 lg:py-120 bg-purple-dark lg:bg-purple-lighter text-purple what-unique unique-timestudy"
    >
      <div class="container-contents">
        <div class="container mx-auto lg:w-2/3">
          <h5 class="article-title">Visual Design, Iteration & Development</h5>
          <p class="article-paragraph font-medium">
            The visual design and prototype were generated based on the
            wireframes, recommendations from the usability tests and Time
            Study’s brand guide. We explored different visual treatments which
            centered around:
          </p>
        </div>
      </div>

      <!--- First Image -->
      <div
        class="flex flex-wrap justify-between items-center lg:pr-20 mt-20 lg:mt-120 px-6 lg:pr-20 lg:pl-0"
      >
        <div class="w-full flex justify-start lg:w-3/6 order-2 lg:order-1">
          <figure>
            <img
              class="object-cover object-center"
              src="../assets/images/timestudy/timestudy-7.png"
              alt="Screenshot of Timestudy App"
            />
          </figure>
        </div>

        <article
          class="w-full lg:w-1/2 flex justify-end order-1 lg:order-2 mb-16 lg:mb-0"
        >
          <div class="w-full lg:w-11/12">
            <p class="article-paragraph mt-4 pr-5">
              Seamlessly starting and stopping activities and making it easy for
              practitioners to identify which activity they want to start
              timing.
            </p>
          </div>
        </article>
      </div>

      <!--- Second Image -->
      <div
        class="flex flex-wrap justify-between items-center mt-120 lg:mt-60 px-6 lg:pl-36 lg:pr-0"
      >
        <article class="w-full lg:w-1/2">
          <div class="container lg:w-11/12">
            <p class="article-paragraph">
              Allowing practitioners to seamlessly link and switch between their
              health-care organisations on TS Now.
            </p>
          </div>
        </article>

        <div class="w-full flex justify-end lg:w-1/2 mt-16 lg:mt-0">
          <figure>
            <img
              class="object-cover object-center rounded"
              src="../assets/images/timestudy/timestudy-8.png"
              alt="Screenshot of Timestudy App"
            />
          </figure>
        </div>
      </div>

      <!--- Third Image -->
      <div
        class="flex flex-wrap justify-between items-center lg:pr-20 mt-20 lg:mt-120 px-6 lg:pr-20 lg:pl-0"
      >
        <div class="w-full flex justify-start lg:w-3/6 order-2 lg:order-1">
          <figure>
            <img
              class="object-cover object-center"
              src="../assets/images/timestudy/timestudy-9.png"
              alt="Screenshot of Timestudy App"
            />
          </figure>
        </div>

        <article
          class="w-full lg:w-1/2 flex justify-end order-1 lg:order-2 mb-16 lg:mb-0"
        >
          <div class="w-full lg:w-11/12">
            <p class="article-paragraph mt-4 pr-5">
              Making it easy for practitioners to edit details of activity
              sessions they’ve timed, thereby eliminating issues related to
              forgetting to stop timing, selecting the wrong activity from the
              activity list, etc.
            </p>
          </div>
        </article>
      </div>

      <!--- Fourth Image -->
      <div
        class="flex flex-wrap justify-between items-center mt-120 lg:mt-60 px-6 lg:pl-36 lg:pr-0"
      >
        <article class="w-full lg:w-1/2">
          <div class="container lg:w-11/12">
            <p class="article-paragraph">
              We also added status for activity sessions, so practitioners know
              their times studies have synced with Times Study’s enterprise
              platform. In cases where the sync failed, TS Now would keep trying
              to sync with the enterprise platform until the sync is successful.
            </p>
          </div>
        </article>

        <div class="w-full flex justify-end lg:w-1/2 mt-16 lg:mt-0">
          <figure>
            <img
              class="object-cover object-center rounded"
              src="../assets/images/timestudy/timestudy-10.png"
              alt="Screenshot of Timestudy App"
            />
          </figure>
        </div>
      </div>

      <div class="container-contents mt-16 lg:mt-20">
        <div class="container mx-auto lg:w-2/3">
          <p class="article-paragraph text-purple font-medium">
            We kept iterating on the visual design up until and even through
            development. <br />
            Development was done in milestones, with each milestone going
            through quality assurance before being deployed.
          </p>
        </div>
      </div>
    </section>

    <article
      class="py-20 lg:py-120 text-black-dark bg-white usability-testing from-beta"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Recap & Takeaways</h5>
          <h6 class="article-title title-2 text-lg lg:text-2xl mt-6 lg:mt-8">
            Deeply Understanding Practitioners Context Helped in Planning for
            Alternative Use Cases
          </h6>
          <p class="article-paragraph">
            While testing the designs, we realised that if practitioners forget
            to log activities, there was no way for them to add activities to
            their summaries. Due to the nature of their work, forgetting to log
            activities is highly likely. We curbed this, by allowing
            practitioners to manually add activities (i.e add activity name,
            start time and end time). This provided a way for summaries to be
            kept as accurate as possible.
          </p>
        </div>

        <div class="w-full lg:w-2/3 mx-auto mt-16 lg:mt-20">
          <h6 class="article-title title-2">
            Colour and Iconography were Strong in Building Familiarity with
            Activities
          </h6>
          <p class="article-paragraph">
            Ensuring that practitioners could easily start and stop timing
            activities was extremely essential. Behaviours as small as, having
            to stop to read activity names before starting an activity, could
            cause some friction for practitioners in the time study process.
            Using colours and icons to build familiarity within the app and
            thereby reduce friction, worked well based on feedback from the
            Product Owners.
          </p>
        </div>
      </div>
    </article>

    <section
      class="px-6 pt-20 lg:py-28 lg:px-20 text-black-dark bg-white hidden"
    >
      <article class="container mx-auto lg:px-20 lg:w-2/3">
        <h5 class="article-title text-black-dark">Recap & Takeaways</h5>
        <h6 class="article-title leading-8 text-lg lg:text-2xl mt-6 lg:mt-8">
          Deeply Understanding Practitioners Context Helped in Planning for
          Alternative Use Cases
        </h6>
        <p class="article-paragraph text-black-dark font-normal">
          While testing the designs, we realised that if practitioners forget to
          log activities, there was no way for them to add activities to their
          summaries. Due to the nature of their work, forgetting to log
          activities is highly likely. We curbed this, by allowing practitioners
          to manually add activities (i.e add activity name, start time and end
          time). This provided a way for summaries to be kept as accurate as
          possible.
        </p>

        <h6
          class="article-title leading-8 text-lg lg:text-2xl mt-6 lg:mt-8 mt-16 lg:mt-20"
        >
          Colour and Iconography were Strong in Building Familiarity with
          Activities
        </h6>
        <p class="article-paragraph text-black-dark font-normal">
          Ensuring that practitioners could easily start and stop timing
          activities was extremely essential. Behaviours as small as, having to
          stop to read activity names before starting an activity, could cause
          some friction for practitioners in the time study process. Using
          colours and icons to build familiarity within the app and thereby
          reduce friction, worked well based on feedback from the Product
          Owners.
        </p>
      </article>
    </section>

    <section class="py-20 lg:py-120 how-we-got-here bg-white">
      <article
        class="container-contents flex flex-wrap lg:flex-nowrap justify-center lg:justify-start items-center"
      >
        <figure class="w-full lg:w-8/12">
          <img
            src="../assets/images/timestudy/timestudy-11.png"
            alt="Download mobile app"
          />
        </figure>

        <div class="lg:w-30"></div>

        <div class="flex flex-col w-2/3 lg:w-1/3 mt-2.5 lg:mt-0 xl:px-5">
          <p class="text-gray-lighter font-semibold text-center lg:text-left">
            LEARN MORE ABOUT
          </p>
          <a
            class="mt-6 lg:mt-8"
            href="https://www.timestudy.co/mobile"
            target="_blank"
          >
            <img
              src="../assets/images/timestudy/timestudy-bottom.png"
              alt="Download mobile app"
            />
          </a>
        </div>
      </article>
    </section>

    <div class="container-contents flex flex-col lg:flex-row justify-between">
      <div></div>

      <div class="flex justify-center flex-col order-2 lg:order-1 mb-6 lg:mb-0">
        <p class="text-gray-lighter font-semibold text-center lg:text-right">
          Next
        </p>
        <div class="flex justify-center items-center mt-6 lg:mt-8">
          <div class="w-full text-center lg:text-left relative">
            <a
              href="/plexus"
              class="timestudy-link-border outline-none focus:outline-none article-link text-purple text-lg"
            >
              Plexus Case Study
            </a>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Logo from "@/components/Logo";

export default {
  name: "TimeStudy",
  components: { Logo, Footer },
};
</script>

<style lang="scss" scoped>
.timestudy-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #440a67;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
}

.timestudy-link-border:hover:before {
  animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
